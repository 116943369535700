<style>
  .bd-color {
    border-radius: 2px;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,.1), inset 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    float: left;
    height: 24px;
    margin-right: 8px;
    width: 24px;
  }
</style>

<template>
  <table>
    <tr
      v-for="color in colors"
    >
      <td
        :class="`bd-color has-background-${color}`"
      />
      <td>
        {{ color }}
      </td>
    </tr>
  </table>
</template>

<script>

const colors = [
  'white',
  'black',
  'light',
  'dark',
  'primary',
  'info',
  'link',
  'success',
  'warning',
  'danger',
  'black-bis',
  'black-ter',
  'grey-darker',
  'grey-dark',
  'grey',
  'grey-light',
  'grey-lighter',
  'white-ter',
  'white-bis'
]

export default {
  data () {
    return {
      colors
    }
  }
}

</script>
